export const dashboardTrans = {
  subscription: {
    active: "Your subscription is active",
    error: "The account does not have an active subscription!",
    extend: "Extend the plan",
    extend_limits: "Extends limits",
    using_trial: "You benefit from a Free plan",
    left_days: "There are {{leftDays}} days left on your subscription.",
    left_days_in_current: "There are {{leftDays}} days left in the current plan.",
    left_days_period: "Your limits will be renewed in {{leftDays}} days.",
    plan_name: "Plan {{type}}",
    choose_plan: "Select a plan",
    plans: {
      standard: "Standard",
      starter: "Starter",
      premium: "Premium",
      trial: "Free trial period",
      monthPlan: "Monthly",
      quarterPlan: "Quarterly",
      yearPlan: "Annual",
      month: "Month",
      three_months: "3 months",
      twelve_months: "12 months",
    },
    plan_is_ending: "Your subscription is coming to an end",
    using_plan: "You are currently using a plan {{type}}",
    welcome_back: "Welcome back",
    nice_to_see_you: "Nice to see you",
  },
  risk_in_parties: "Risk assessment in parties",
  risk_in_transactions: "Risk assessment in transactions",
  parties_status: "Parties statuses",
  transactions_status: "Transaction statuses",
  number_of_evaluations: "Number of ratings",
  no_data: "No data",
};
